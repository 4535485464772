body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.navigation {
  align-items: center;
  display: flex;
  min-height: 5em;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.navigation__links-wrapper {
  padding: 0 2.5em;
  display: flex;
  width: 100%;
}
.navigation__link {
  margin: 0 2em !important;
  text-decoration: none;
}
.navigation__back-to-homepage-wrapper,
.navigation__buttons {
  margin-left: auto;
  display: flex;
}
@media screen and (max-width: 500px) {
  .navigation__back-to-homepage-wrapper {
    margin-right: auto;
  }
}
.navigation__back-to-homepage {
  width: 150px;
}
.intro {
  display: flex;
  justify-content: center;
  padding: 8em 0 16em;
}
@media screen and (max-width: 768px) {
  .intro {
    padding: 8em 0 12em;
  }
}
@media screen and (max-width: 600px) {
  .intro {
    padding: 8em 0 10em;
  }
}
@media screen and (max-width: 500px) {
  .intro {
    padding: 8em 0;
  }
}
.intro .col {
  align-items: center;
}
.intro__logo {
  width: 169px;
}
@media screen and (max-width: 1024px) {
  .intro__logo {
    width: 140px;
  }
}
@media screen and (max-width: 768px) {
  .intro__logo {
    width: 124px;
  }
}
@media screen and (max-width: 600px) {
  .intro__logo {
    width: 100px;
  }
}
@media screen and (max-width: 500px) {
  .intro__logo {
    width: 90px;
  }
}
.intro__title {
  display: flex;
  font-size: 80px;
  margin: 0.5em 0;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .intro__title {
    font-size: 68px;
  }
}
@media screen and (max-width: 768px) {
  .intro__title {
    font-size: 58px;
  }
}
@media screen and (max-width: 600px) {
  .intro__title {
    font-size: 46px;
  }
}
@media screen and (max-width: 500px) {
  .intro__title {
    font-size: 40px;
  }
}
.intro__registered-trademark {
  display: flex;
  font-size: 38px;
  font-weight: 400;
}
@media screen and (max-width: 1024px) {
  .intro__registered-trademark {
    font-size: 30px;
  }
}
.intro__subtitle {
  font: normal normal 300 30px/42px Montserrat;
  margin: 0 0 1.5em;
  max-width: 530px;
  text-align: center;
}
.intro__subtitle--highlighted {
  font-weight: 700;
}
@media screen and (max-width: 1024px) {
  .intro__subtitle {
    font-size: 26px;
    line-height: 38px;
  }
}
@media screen and (max-width: 600px) {
  .intro__subtitle {
    font-size: 22px;
    line-height: 34px;
  }
}
@media screen and (max-width: 500px) {
  .intro__subtitle {
    font-size: 20px;
    line-height: 32px;
  }
}
.intro__button {
  background: #e6134f 0 0 no-repeat padding-box !important;
  border-radius: 41px !important;
  font: normal normal 600 24px/40px Montserrat !important;
  text-transform: none !important;
  width: 145px;
}
@media screen and (max-width: 1024px) {
  .intro__button {
    font: normal normal 600 20px/36px Montserrat !important;
  }
}
.intro__available-soon {
  font: normal normal 600 16px/24px Montserrat;
  margin: 1em 0 0;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .intro__available-soon {
    font-size: 14px;
  }
}
.intro__app-stores {
  display: flex;
  flex: 1 1;
  margin: 2em 0 0;
}
.about {
  background-color: #e6134f;
  display: flex;
  justify-content: center;
  padding-bottom: 10em;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .about {
    padding-bottom: 9em;
  }
}
@media screen and (max-width: 500px) {
  .about {
    padding-bottom: 7em;
  }
}
@media screen and (max-width: 450px) {
  .about {
    padding-bottom: 5em;
  }
}
.about .col {
  align-items: center;
}
.about__title-img-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 10em 0 14em;
  position: relative;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .about__title-img-wrapper {
    padding: 8em 0 12em;
  }
}
@media screen and (max-width: 768px) {
  .about__title-img-wrapper {
    padding: 5em 0 10em;
  }
}
@media screen and (max-width: 600px) {
  .about__title-img-wrapper {
    padding: 4em 0 9em;
  }
}
@media screen and (max-width: 500px) {
  .about__title-img-wrapper {
    padding: 3em 0 6em;
  }
}
@media screen and (max-width: 450px) {
  .about__title-img-wrapper {
    padding: 2em 0 4em;
  }
}
.about__title {
  font: normal normal 300 80px/54px Montserrat;
  margin: 0 0 1.5em;
  position: relative;
  z-index: 5;
}
.about__title--highlighted {
  font-weight: 700;
}
@media screen and (max-width: 1024px) {
  .about__title {
    font-size: 54px;
  }
}
@media screen and (max-width: 768px) {
  .about__title {
    font-size: 44px;
  }
}
@media screen and (max-width: 600px) {
  .about__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 500px) {
  .about__title {
    font-size: 32px;
  }
}
@media screen and (max-width: 450px) {
  .about__title {
    font-size: 26px;
    white-space: nowrap;
  }
}
.about__img {
  left: 50%;
  position: absolute;
  top: -65px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 500px;
}
@media screen and (max-width: 1024px) {
  .about__img {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .about__img {
    width: 325px;
  }
}
@media screen and (max-width: 600px) {
  .about__img {
    width: 290px;
  }
}
@media screen and (max-width: 500px) {
  .about__img {
    width: 230px;
  }
}
@media screen and (max-width: 450px) {
  .about__img {
    width: 190px;
  }
}
.about__text {
  font: normal normal 300 28px/38px Montserrat;
  margin-bottom: 3em;
  text-align: center;
}
.about__text--highlighted {
  font-weight: 600;
}
@media screen and (max-width: 1024px) {
  .about__text {
    font-size: 26px;
    line-height: 34px;
  }
}
@media screen and (max-width: 768px) {
  .about__text {
    font-size: 22px;
    line-height: 30px;
  }
}
@media screen and (max-width: 600px) {
  .about__text {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 500px) {
  .about__text {
    font-size: 18px;
  }
}
.about__big-five-logo {
  width: 273px;
}
@media screen and (max-width: 1024px) {
  .about__big-five-logo {
    width: 223px;
  }
}
@media screen and (max-width: 768px) {
  .about__big-five-logo {
    width: 180px;
  }
}
@media screen and (max-width: 600px) {
  .about__big-five-logo {
    width: 160px;
  }
}
@media screen and (max-width: 500px) {
  .about__big-five-logo {
    width: 120px;
  }
}
.more {
  background-color: #3149ad;
  display: flex;
  justify-content: center;
  padding: 10em 0;
}
@media screen and (max-width: 768px) {
  .more {
    padding: 8em 0;
  }
}
@media screen and (max-width: 600px) {
  .more {
    padding: 6em 0;
  }
}
@media screen and (max-width: 450px) {
  .more {
    padding: 4em 0;
  }
}
.more .row {
  margin-bottom: 8em;
}
@media screen and (max-width: 900px) {
  .more .row:first-child,
  .more .row:last-child {
    flex-wrap: wrap-reverse;
  }
}
@media screen and (max-width: 500px) {
  .more .row {
    margin-bottom: 6em;
  }
}
@media screen and (max-width: 1200px) {
  .more .col.align-items-end {
    align-items: flex-start;
  }
}
@media screen and (max-width: 900px) {
  .more .col,
  .more .col.align-items-end {
    align-items: center;
  }
  .more .col {
    flex: 1 1 auto;
  }
}
.more__img {
  max-width: 500px;
}
@media screen and (max-width: 1200px) {
  .more__img {
    width: 400px;
  }
}
@media screen and (max-width: 991px) {
  .more__img {
    width: 325px;
  }
}
@media screen and (max-width: 900px) {
  .more__img {
    width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .more__img {
    width: 325px;
  }
}
@media screen and (max-width: 600px) {
  .more__img {
    width: 290px;
  }
}
@media screen and (max-width: 500px) {
  .more__img {
    width: 230px;
  }
}
@media screen and (max-width: 450px) {
  .more__img {
    width: 190px;
  }
}
.more__title-and-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: 500px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .more__title-and-text {
    align-items: center;
  }
}
.more__title-wrapper {
  position: relative;
}
.more__title {
  font: normal normal 300 80px/54px Montserrat;
  white-space: nowrap;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.more__title--justify-start {
  justify-content: flex-start;
  margin-left: -8px;
}
.more__title--highlighted {
  font-weight: 600;
  margin-left: 16px;
}
@media screen and (max-width: 1200px) {
  .more__title {
    font-size: 76px;
  }
}
@media screen and (max-width: 900px) {
  .more__title {
    justify-content: center;
    margin-top: 0;
  }
}
@media screen and (max-width: 991px) {
  .more__title {
    font-size: 54px;
  }
}
@media screen and (max-width: 768px) {
  .more__title {
    font-size: 44px;
  }
}
@media screen and (max-width: 600px) {
  .more__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 500px) {
  .more__title {
    font-size: 32px;
  }
}
@media screen and (max-width: 450px) {
  .more__title {
    font-size: 26px;
    white-space: nowrap;
  }
}
.more__text {
  font: normal normal 300 26px/36px Montserrat;
  margin: 0;
  padding-left: 2.5em;
  text-align: left;
}
.more__text--no-padding-left {
  padding-left: 0;
}
.more__text--highlighted {
  font-weight: 600;
}
@media screen and (max-width: 991px) {
  .more__text {
    padding-left: 0;
  }
}
@media screen and (max-width: 900px) {
  .more__text {
    margin-bottom: 3em;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .more__text {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 2em;
  }
}
@media screen and (max-width: 600px) {
  .more__text {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 500px) {
  .more__text {
    font-size: 18px;
  }
}
.get-iwi {
  background-color: #e6134f;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .get-iwi .row {
    flex-direction: column-reverse;
    flex-wrap: wrap-reverse;
  }
}
.get-iwi .row .col {
  padding: 0;
}
@media screen and (max-width: 768px) {
  .get-iwi .row .col {
    padding: 0 1em;
  }
}
.get-iwi__title {
  font: normal normal 300 60px/54px Montserrat;
  white-space: nowrap;
}
@media screen and (max-width: 1024px) {
  .get-iwi__title {
    font-size: 54px;
  }
}
@media screen and (max-width: 768px) {
  .get-iwi__title {
    font-size: 44px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .get-iwi__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 500px) {
  .get-iwi__title {
    font-size: 32px;
  }
}
@media screen and (max-width: 450px) {
  .get-iwi__title {
    font-size: 26px;
    white-space: nowrap;
  }
}
.get-iwi__title--highlighted {
  font-weight: 600;
}
.get-iwi__gif {
  max-width: 500px;
  margin: auto auto 0;
  width: 100%;
}
.get-iwi__text {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  font: normal normal 300 26px/36px Montserrat;
  margin-bottom: 2.5em;
  text-align: center;
}
.get-iwi__text--highlighted {
  font-weight: 600;
}
.get-iwi__app-store-images {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
}
.newsletter {
  display: flex;
  justify-content: center;
  padding: 8em 0;
}
.newsletter__title {
  font: normal normal 300 45px/54px Montserrat;
  margin: 0 0 1.2em;
  max-width: 700px;
  text-align: center;
}
.newsletter__title--highlighted {
  font-weight: 600;
}
@media screen and (max-width: 900px) {
  .newsletter__title {
    font-size: 40px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .newsletter__title {
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and (max-width: 500px) {
  .newsletter__title {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (max-width: 450px) {
  .newsletter__title {
    font-size: 26px;
  }
}
.newsletter__consent {
  font: normal normal 300 16px/24px Montserrat;
  margin: 0;
  max-width: 500px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .newsletter__consent {
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .newsletter__consent {
    font-size: 14px;
    line-height: 20px;
  }
}
.newsletter__link {
  text-decoration: underline !important;
}
.newsletter-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 3em;
}
.newsletter-form .MuiTextField-root {
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  height: 56px;
  margin-bottom: 1em;
  width: 400px;
}
@media screen and (max-width: 600px) {
  .newsletter-form .MuiTextField-root {
    height: 46px;
    width: 300px;
  }
}
.newsletter-form fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}
.newsletter-form input#email {
  color: #fff;
  font: normal normal 600 17px/20px Montserrat;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .newsletter-form input#email {
    padding: 10.5px 8px;
  }
}
.newsletter-form .MuiButton-root.newsletter-form__button {
  background: #fff;
  border-radius: 30px;
  color: #e6134f;
  font: normal normal 600 17px/20px Montserrat;
  height: 58px;
}
.newsletter-form .MuiButton-root.newsletter-form__button:disabled {
  opacity: 0.6;
}
@media screen and (max-width: 600px) {
  .newsletter-form .MuiButton-root.newsletter-form__button {
    height: 48px;
    width: 300px;
  }
}
.newsletter-form .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.social-icons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  min-width: 350px;
}
@media screen and (max-width: 768px) {
  .social-icons {
    margin: 1.5em auto 0;
  }
}
@media screen and (max-width: 365px) {
  .social-icons {
    min-width: 100%;
  }
}
.social-icons__icon {
  height: 45px;
  width: 45px;
}
.social-icons__icon img {
  max-height: 100%;
  max-width: 100%;
}
@media screen and (max-width: 1200px) {
  .social-icons__icon {
    height: 40px;
    width: 40px;
  }
}
@media screen and (max-width: 900px) {
  .social-icons__icon {
    height: 34px;
    width: 34px;
  }
}
@media screen and (max-width: 768px) {
  .social-icons__icon {
    height: 30px;
    width: 30px;
  }
}
@media screen and (max-width: 600px) {
  .social-icons__icon {
    height: 26px;
    width: 26px;
  }
}
@media screen and (max-width: 500px) {
  .social-icons__icon {
    margin: 0 0 1.5em;
  }
}
@media screen and (max-width: 450px) {
  .social-icons__icon {
    height: 22px;
    width: 22px;
  }
}
.social-icons__icon.--linkedin {
  width: 54px;
}
@media screen and (max-width: 1200px) {
  .social-icons__icon.--linkedin {
    width: 49px;
  }
}
@media screen and (max-width: 900px) {
  .social-icons__icon.--linkedin {
    width: 43px;
  }
}
@media screen and (max-width: 768px) {
  .social-icons__icon.--linkedin {
    width: 39px;
  }
}
@media screen and (max-width: 600px) {
  .social-icons__icon.--linkedin {
    width: 35px;
  }
}
@media screen and (max-width: 450px) {
  .social-icons__icon.--linkedin {
    width: 31px;
  }
}
.navigation .social-icons {
  min-width: 100px;
}
@media screen and (max-width: 991px) {
  .navigation .social-icons {
    min-width: 85px;
  }
}
@media screen and (max-width: 500px) {
  .navigation .social-icons {
    min-width: 65px;
  }
}
.navigation .social-icons__icon {
  height: 38px;
  width: 38px;
}
@media screen and (max-width: 900px) {
  .navigation .social-icons__icon {
    height: 34px;
    width: 34px;
  }
}
@media screen and (max-width: 768px) {
  .navigation .social-icons__icon {
    height: 30px;
    width: 30px;
  }
}
@media screen and (max-width: 600px) {
  .navigation .social-icons__icon {
    height: 24px;
    width: 24px;
  }
}
.footer {
  background-color: #31333f;
  display: flex;
  justify-content: center;
  min-height: 300px;
  padding: 10em 0 3em;
}
@media screen and (max-width: 500px) {
  .footer {
    padding: 6em 0 3em;
  }
}
.footer__content {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .footer__content {
    padding: 0 1em;
  }
}
@media screen and (max-width: 768px) {
  .footer__content {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 500px) {
  .footer__content {
    align-items: center;
    flex-direction: column;
    padding-bottom: 5em;
  }
}
.footer__logo {
  margin-right: 2em;
  width: 93px;
}
@media screen and (max-width: 900px) {
  .footer__logo {
    width: 73px;
  }
}
@media screen and (max-width: 768px) {
  .footer__logo {
    width: 63px;
  }
}
@media screen and (max-width: 600px) {
  .footer__logo {
    width: 50px;
    margin-right: 1em;
  }
}
@media screen and (max-width: 500px) {
  .footer__logo {
    margin: 0 0 1em;
    width: 93px;
  }
}
.footer__title {
  font: normal normal 300 40px/54px Montserrat;
  margin: 0;
}
.footer__title--highlighted {
  font-weight: 600;
}
@media screen and (max-width: 1200px) {
  .footer__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 900px) {
  .footer__title {
    font-size: 32px;
  }
}
@media screen and (max-width: 768px) {
  .footer__title {
    font-size: 28px;
  }
}
@media screen and (max-width: 600px) {
  .footer__title {
    font-size: 22px;
  }
}
@media screen and (max-width: 450px) {
  .footer__title {
    font-size: 20px;
  }
}
.footer__bottom-row {
  position: absolute;
  bottom: 0;
}
.footer__links {
  display: flex;
  justify-content: flex-start;
  padding-left: 1em;
}
@media screen and (max-width: 768px) {
  .footer__links {
    justify-content: space-evenly;
    margin: 0 auto 1.5em;
    padding-left: 0;
    width: 100%;
  }
}
.footer__bottom-link {
  margin-right: 2em !important;
}
@media screen and (max-width: 768px) {
  .footer__bottom-link {
    margin-right: 0 !important;
  }
}
.footer___trademark {
  font-size: 14px;
  margin: 0 0 0 auto;
  padding-right: 1em;
}
@media screen and (max-width: 768px) {
  .footer___trademark {
    font-size: 12px;
    margin: 0;
    text-align: center;
    width: 100%;
  }
}
.privacy-policy {
  background-color: #31333f;
  display: flex;
  justify-content: center;
  padding: 8em 0;
}
@media screen and (max-width: 768px) {
  .privacy-policy {
    padding: 6em 0 4em;
  }
}
@media screen and (max-width: 1200px) {
  .privacy-policy .col {
    padding: 0 2em;
  }
}
.privacy-policy__logo {
  margin-bottom: 3em;
  width: 169px;
}
@media screen and (max-width: 1024px) {
  .privacy-policy__logo {
    width: 140px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-policy__logo {
    width: 124px;
  }
}
@media screen and (max-width: 600px) {
  .privacy-policy__logo {
    margin-bottom: 2em;
    width: 100px;
  }
}
@media screen and (max-width: 500px) {
  .privacy-policy__logo {
    margin-left: auto;
    margin-right: auto;
    width: 90px;
  }
}
.privacy-policy__title {
  font: normal normal 300 60px/70px Montserrat;
}
.privacy-policy__title--highlighted {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .privacy-policy__title {
    font-size: 44px;
    line-height: 60px;
  }
}
@media screen and (max-width: 500px) {
  .privacy-policy__title {
    font-size: 32px;
    line-height: 40px;
    text-align: center;
  }
}
.privacy-policy__hint {
  font: normal normal normal 22px/32px Montserrat;
}
@media screen and (max-width: 1024px) {
  .privacy-policy__hint {
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-policy__hint {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 500px) {
  .privacy-policy__hint {
    text-align: center;
  }
}
.privacy-policy__subtitle {
  font: normal normal 700 28px/32px Montserrat;
}
@media screen and (max-width: 1024px) {
  .privacy-policy__subtitle {
    font-size: 24px;
    line-height: 28px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-policy__subtitle {
    font-size: 20px;
    line-height: 24px;
  }
}
.privacy-policy__list-item,
.privacy-policy__text {
  font: normal normal normal 18px/24px Montserrat;
  margin: 0 0 1em;
}
@media screen and (max-width: 1024px) {
  .privacy-policy__list-item,
  .privacy-policy__text {
    font-size: 16px;
    line-height: 22px;
  }
}
.privacy-policy__back-to-homepage-wrapper {
  display: flex;
  margin-left: auto;
  margin-top: 3em;
}
@media screen and (max-width: 500px) {
  .privacy-policy__back-to-homepage-wrapper {
    margin-right: auto;
  }
}
.privacy-policy__back-to-homepage {
  width: 150px;
}
.quiz {
  background-color: #e6134f;
  display: flex;
  justify-content: center;
  padding: 4em 0;
  width: 100%;
}
.quiz__logo {
  width: 169px;
}
@media screen and (max-width: 1024px) {
  .quiz__logo {
    width: 140px;
  }
}
@media screen and (max-width: 768px) {
  .quiz__logo {
    width: 124px;
  }
}
@media screen and (max-width: 600px) {
  .quiz__logo {
    width: 100px;
  }
}
@media screen and (max-width: 500px) {
  .quiz__logo {
    width: 90px;
  }
}
.quiz__title {
  align-items: center;
  display: flex;
  flex: 1 1;
  font: normal normal 700 80px/84px Montserrat;
  justify-content: space-evenly;
  margin: 0 0 1em;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  .quiz__title {
    font-size: 54px;
  }
}
@media screen and (max-width: 768px) {
  .quiz__title {
    font-size: 44px;
  }
}
@media screen and (max-width: 600px) {
  .quiz__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 500px) {
  .quiz__title {
    font-size: 32px;
  }
}
@media screen and (max-width: 450px) {
  .quiz__title {
    font-size: 26px;
    white-space: nowrap;
  }
}
.quiz__subtitle {
  font: normal normal 300 30px/42px Montserrat;
  margin: 0 0 1.5em;
  text-align: center;
}
.quiz__subtitle--highlighted {
  font-weight: 700;
}
@media screen and (max-width: 1024px) {
  .quiz__subtitle {
    font-size: 26px;
    line-height: 38px;
  }
}
@media screen and (max-width: 600px) {
  .quiz__subtitle {
    font-size: 22px;
    line-height: 34px;
  }
}
@media screen and (max-width: 500px) {
  .quiz__subtitle {
    font-size: 20px;
    line-height: 32px;
  }
}
.quiz__language-row {
  display: flex;
  flex: 1 1;
  justify-content: center;
}
.quiz__language-flag {
  border: 2px solid #fff;
  border-radius: 4px;
  cursor: pointer;
  height: auto;
  width: 115px;
}
.quiz__language-flag:first-child {
  margin-right: 20px;
}
@media screen and (max-width: 768px) {
  .quiz__language-flag {
    width: 95px;
  }
}
@media screen and (max-width: 500px) {
  .quiz__language-flag {
    width: 70px;
  }
}
.quiz__question {
  font-size: 18px;
  font-weight: 700;
  padding: 0 2em;
}
.quiz__answers {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  flex: 1 1;
  flex-direction: column;
  padding: 1em;
}
.quiz__answer,
.quiz__answers {
  display: flex;
  justify-content: center;
}
.quiz__answer {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  flex: 1 1;
  margin: auto;
  padding: 0 1em;
  position: relative;
  width: 90%;
  text-align: left;
}
.quiz__answer:active,
.quiz__answer:focus,
.quiz__answer:hover {
  background-color: #e6134f;
  color: #fff;
}
.quiz__answer:before {
  content: "";
  background: #e6134f;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  left: 0.25em;
}
.quiz__answer-text {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 66px;
  width: 100%;
}
.quiz__score-row {
  padding: 4em 0;
}
.quiz__score {
  align-items: center;
  display: flex;
  font-size: 80px;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.swiper {
  max-width: 500px;
}
.swiper-slide {
  padding: 0 1em;
  width: calc(100% - 2em) !important;
}
body,
html {
  min-height: 100%;
}
body {
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
a {
  color: #fff !important;
  font: normal normal 700 16px/14px Montserrat;
  /* text-decoration: none !important; */
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.container {
  margin: 0 auto;
  max-width: 1080px;
  position: relative;
  width: 100%;
}
.row {
  display: flex;
  flex: 1 1;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .row {
    flex-wrap: wrap;
  }
}
.col {
  box-sizing: border-box;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  max-width: 100%;
}
@media screen and (max-width: 1200px) {
  .col {
    padding: 0 1em;
  }
}
.col--justify-center {
  justify-content: center;
}
.app-store-img {
  height: 45px;
  width: 152px;
}
.app-store-img.--ios {
  margin-right: 30px;
}
/*# sourceMappingURL=main.0b30af6c.chunk.css.map */
